//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
import Report from './Report.vue';
export default {
  components: { Report },
  data() {
    return {
      bookingId: '',
      record: null,
      rooms: {},
      order: '',
    };
  },
  methods: {
    async fetchReport() {
      const BASE_URL =
        window.location.hostname === 'localhost'
          ? process.env.VUE_APP_DEV_BASE_URL
          : process.env.VUE_APP_PROD_BASE_URL;
      const result = await axios.get(
        `${BASE_URL}/bookings/receipt/${this.bookingId}`
      );
      this.record = result.data.bookingData;
      this.rooms = result.data.roomData;
      const orderDetails = await axios.get(
        `${BASE_URL}/bookings/order/${this.bookingId}`
      );
      this.order = orderDetails.data[0];
    },
  },
};
