//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
export default {
  props: ['record', 'rooms', 'order'],
  computed: {
    getFormattedDays() {
      let checkIn = this.record.checkIn;
      let checkOut = this.record.checkOut;
      checkIn = moment(new Date(checkIn)).format('dddd, MMMM Do YYYY');
      checkOut = moment(new Date(checkOut)).format('dddd, MMMM Do YYYY');
      let duration = moment(new Date(this.record.checkOut)).diff(
        moment(new Date(this.record.checkIn)),
        'days'
      );
      return {
        checkIn,
        checkOut,
        duration,
      };
    },
    getRoomData() {
      let totalRooms = this.rooms.length;
      let categories = [];
      this.rooms.forEach((room) => {
        if (!categories.includes(room.room_type)) {
          categories.push(room.room_type);
        }
      });
      return { totalRooms, categories: categories.join(', ') };
    },
  },
};
