//
//
//
//
//
//
//
//
//
//
//
//

import Sidebar from '@/components/Sidebar.vue';
import Dashboard from '@/components/Dashboard/Dashboard.vue';
import Inventory from '@/components/Inventory/Inventory.vue';
import Bookings from '@/components/Bookings/Bookings.vue';
import Login from '@/components/Auth/Login.vue';
import Receipt from '@/components/Receipts/Receipt.vue';
import Users from '@/components/Users/Users.vue';
import Logs from '@/components/Logs/Logs.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'Home',
  components: {
    Sidebar,
    Dashboard,
    Inventory,
    Bookings,
    Login,
    Receipt,
    Users,
    Logs,
  },
  computed: {
    ...mapGetters(['vuex_get_navigation', 'current_user']),
  },
  created() {
    this.$store.dispatch(
      'setAccessToken',
      localStorage.getItem('access_token')
    );
    this.$store.dispatch('setUser', JSON.parse(localStorage.getItem('user')));
  },
};
