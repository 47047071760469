//
//
//
//
//
//
//
//
//

export default {
  props: ['data', 'headers', 'limit'],
};
