const state = {
  navigations: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
    },
    {
      title: 'Inventory',
      icon: 'mdi-key-variant',
    },
    {
      title: 'Bookings',
      icon: 'mdi-notebook',
    },
    {
      title: 'Receipt',
      icon: 'mdi-file-find',
    },
    {
      title: 'Users',
      icon: 'mdi-account',
    },
    {
      title: 'Logs',
      icon: 'mdi-book-search-outline',
    },
    {
      title: 'Revenue',
      icon: 'mdi-currency-inr',
    },
    {
      title: 'Menu',
      icon: 'mdi-food',
    },
  ],
  current: 'Dashboard',
};

const actions = {
  vuex_Navigate({ commit }, navigator) {
    commit('NAVIGATE', navigator);
  },
};

const mutations = {
  NAVIGATE(state, value) {
    state.current = value;
  },
};

const getters = {
  vuex_get_navigation(state) {
    return state.current;
  },
  vuex_get_navigation_list(state) {
    return state.navigations;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
