//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/api';
export default {
  async created() {
    const result = await api.fetchAppLogs();
    if (result[0]) {
      this.logs = result[1];
    }
  },
  data() {
    return {
      logs: [],
    };
  },
};
