//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/api';
export default {
  data() {
    return {
      formData: {
        username: '',
        password: '',
      },
      errorMessage: '',
    };
  },
  methods: {
    async handleLogin() {
      const user = await api.handleLogin(this.formData);
      if (user) {
        this.$store.dispatch('setAccessToken', user.data.token);
        this.$store.dispatch('setUser', user.data.user);
        return;
      }
      this.errorMessage = 'Invalid User / Password';
    },
  },
};
