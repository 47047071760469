//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Backdrop from '@/components/UI/Backdrop.vue';
import api from '@/api/api';
export default {
  props: ['details'],
  data() {
    return {
      room_details: {
        category: this.details ? this.details.category : '',
        alloted: this.details ? this.details.alloted : '',
        price: this.details ? this.details.price : '',
        bookingEnabled: this.details ? this.details.bookingEnabled : true,
      },
      event_type: this.details ? 'update' : 'add',
    };
  },
  components: {
    Backdrop,
  },
  methods: {
    exit() {
      this.$emit('exit');
    },
    async handleUpdate() {
      if (this.event_type === 'add') {
        await api.addRoomInInventory(this.room_details);
        this.$emit('roomUpdate');
      } else {
        await api.updateRoomInInventory(this.room_details, this.details._id);
        this.$emit('roomUpdate');
      }
    },
    async handleRoomDelete() {
      await api.deleteRoomFromInventory(this.details._id);
      this.$emit('roomUpdate');
    },
  },
};
