//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
  methods: {
    changeNavigator(route) {
      this.$store.dispatch('vuex_Navigate', route.title);
    },
  },
  computed: {
    ...mapGetters(['vuex_get_navigation_list', 'current_user']),
  },
};
