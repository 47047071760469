const state = {
  colors: [
    { fg: '#000', bg: '#FFCDD2' }, // orange
    { fg: '#000', bg: '#E1BEE7' }, // purple
    { fg: '#000', bg: '#82B1FF' }, // blue
    { fg: '#000', bg: '#00796B' }, // green
  ],
  current: { fg: '#000', bg: '#00796B' }, // green
};

const actions = {
  setTheme({ commit }, value) {
    commit('SET_THEME', value);
  },
};

const mutations = {
  SET_THEME(state, value) {
    state.current = value;
  },
};

const getters = {
  vuex_getTheme(state) {
    return state.current;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
