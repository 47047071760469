//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/api';
import DataTable from '@/components/UI/DataTable.vue';
export default {
  components: { DataTable },
  created() {
    this.fetchData();
  },
  data() {
    return {
      data: {
        rooms: [],
        revenue: {
          current: 0,
          total: 0,
        },
        bookings: {
          current: 0,
          total: 0,
        },
        visitor: 0,
      },
    };
  },
  methods: {
    async fetchData() {
      const result = await api.fetchDashboardData();
      this.data = result;
    },
  },
};
