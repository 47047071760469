//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['vuex_getTheme', 'current_user']),
  },
  methods: {
    handleLogout() {
      this.$store.dispatch('logout');
    },
  },
};
