//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import api from '@/api/api';
import DataTable from '@/components/UI/DataTable.vue';
import DatePicker from '@/components/UI/DatePicker.vue';
export default {
  components: { DataTable, DatePicker },
  data() {
    return {
      filters: {
        checkIn: {},
        checkOut: {},
      },
      bookingData: [],
      headers: [
        {
          text: 'Booking Id',
          value: '_id',
        },
        {
          text: 'Guest Name',
          value: 'name',
        },
        { text: 'Check In', value: 'checkIn' },
        { text: 'Check Out', value: 'checkOut' },
        { text: 'Booking Date', value: 'created' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'confirmation' },
      ],
    };
  },
  created() {
    this.fetchBookings();
  },
  methods: {
    computeDates(data) {
      data.map((booking) => {
        booking.checkIn = moment(new Date(booking.checkIn)).format('MMM Do YY');
        booking.checkOut = moment(new Date(booking.checkOut)).format(
          'MMM Do YY'
        );
        booking.created = moment(new Date(booking.created)).format('MMM Do YY');
      });
      this.bookingData = data;
    },
    async fetchBookings() {
      const data = await api.fetchBookings();
      this.computeDates(data);
    },
    async handleSearch() {
      const data = await api.getFilteredBookings(this.filters);
      this.computeDates(data);
    },
    handleDateUpdate(date, index) {
      switch (index) {
        case 1:
          this.filters.checkIn.from = date;
          break;
        case 2:
          this.filters.checkIn.to = date;
          break;
        case 3:
          this.filters.checkOut.from = date;
          break;
        case 4:
          this.filters.checkOut.to = date;
          break;
      }
    },
  },
};
