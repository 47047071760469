//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/api';
import Backdrop from '@/components/UI/Backdrop.vue';
export default {
  components: {
    Backdrop,
  },
  data() {
    return {
      data: {
        name: '',
        username: '',
        email: '',
        password: '',
      },
      errorMessage: '',
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async handleSubmit() {
      const result = await api.addNewUser(this.data);
      if (!result[0]) {
        this.errorMessage = result[1].message;
        return;
      }
      this.$emit('updated');
    },
  },
};
