//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/api';
import AddUser from './AddUser.vue';
export default {
  components: { AddUser },
  data() {
    return {
      userList: [],
      showAddUser: false,
    };
  },
  async created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      const result = await api.fetchUserList();
      if (result[0]) {
        this.userList = result[1];
      }
    },
    handleUserUpdate() {
      this.showAddUser = false;
      this.fetchUsers();
    },
    async handleUserDelete(id) {
      const result = await api.deleteUser(id);
      if (result[0]) {
        this.fetchUsers();
      }
    },
  },
};
