//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Configure from './Configure.vue';
import api from '@/api/api';
export default {
  components: { Configure },
  async created() {
    this.fetchData();
  },
  data() {
    return {
      configure: false,
      selected: null,
      rooms: [],
    };
  },
  methods: {
    async fetchData() {
      const result = await api.fetchRoomList();
      this.rooms = result;
    },
    updateRoom(room) {
      this.selected = room;
      this.configure = true;
    },
    updateRoomDetails() {
      this.configure = false;
      this.fetchData();
    },
  },
};
