import axios from 'axios';
import store from '../store/index';
const BASE_URL =
  window.location.hostname === 'localhost'
    ? process.env.VUE_APP_DEV_BASE_URL
    : process.env.VUE_APP_PROD_BASE_URL;

const http = axios.create({
  baseURL: BASE_URL,
});

http.interceptors.request.use(function (config) {
  config.headers['Authorization'] = localStorage.getItem('access_token');
  const user = JSON.parse(localStorage.getItem('user'));
  config.headers['User'] = user ? user.name : null;

  return config;
});

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      store.dispatch('setAccessToken', null);
      store.dispatch('setUser', null);
    }
  }
);

const api = {
  async fetchRoomList() {
    const result = await http.get(`/inventory`);
    return result.data;
  },
  async addRoomInInventory(data) {
    const result = await http.post(`/inventory`, data);
    return result.data;
  },
  async updateRoomInInventory(data, room_id) {
    const result = await http.post(`/inventory/${room_id}`, data);
    return result.data;
  },
  async deleteRoomFromInventory(room_id) {
    const result = await http.delete(`/inventory/${room_id}`);
    return result.data;
  },
  async fetchBookings() {
    const result = await http.get(`/bookings`);
    return result.data;
  },
  async getFilteredBookings(filters) {
    const result = await http.post(`/bookings/filter`, { filters });
    return result.data;
  },
  async fetchDashboardData() {
    return await (
      await http.get(`/bookings/dashboard`)
    ).data;
  },
  async handleLogin(data) {
    const result = await http.post(`/auth`, data);
    return result;
  },
  async fetchUserList() {
    return await handleHTTP('get', '/auth/list', null, null);
  },
  async addNewUser(data) {
    return await handleHTTP('post', '/auth/create', null, data);
  },
  async deleteUser(id) {
    const url = `/auth/${id}`;
    return await handleHTTP('delete', url, null, null);
  },
  async fetchAppLogs() {
    return await handleHTTP('get', '/logs', null, null);
  },
};

const handleHTTP = async (method, url, headers, data) => {
  try {
    const result = await http({
      method,
      url,
      headers: headers ? headers : {},
      data: data ? data : {},
    });
    return [true, result.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export default api;
