const state = {
  accessToken: null,
  user: null,
};

const actions = {
  setAccessToken({ commit }, payload) {
    localStorage.setItem('access_token', payload);
    commit('SET_ACCESS', payload);
  },
  setUser({ commit }, user) {
    localStorage.setItem('user', JSON.stringify(user));
    commit('SET_USER', user);
  },
  logout({ commit }) {
    localStorage.clear();
    commit('SET_USER', null);
  },
};

const mutations = {
  SET_ACCESS(state, value) {
    state.accessToken = value;
  },
  SET_USER(state, value) {
    state.user = value;
  },
};

const getters = {
  access_token(state) {
    return state.accessToken;
  },
  current_user(state) {
    return state.user;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
